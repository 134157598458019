.App {
  text-align: left;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-nav {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  list-style: none; /* Removes default list styling */
  padding-left: 0; /* Removes default padding */
  overflow-x: auto; /* Enables horizontal scrolling */
  max-width: 100%; /* Adjust this value as needed */
  white-space: nowrap; /* Prevents items from wrapping */
}
.nav-item {
  margin-right: 1rem; /* Adds some space between items */
  flex: 0 0 auto; /* Prevents flex items from growing or shrinking */
}

.nav-link:hover {
  background-color: #1e1f22; /* Light grey background on hover */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.2s ease-in-out; /* Smooth transition */
}

.no-bullets {
  list-style-type: none; /* Removes bullet points */
  padding-left: 0; /* Removes default padding */
}

label { 
  display: block;
  text-align: center;
}

input[type="checkbox"] {
  display: inline-block;
  width: 15px;
  vertical-align: middle;
}

.expiration {
  display: flex;
  justify-content: center;
  font: 14px "Lucida Grande", Helvetica, Arial, sans-serif; 
  color: #000000;
}

.buttons-container {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 10px; /* Optional: Adds some space between the buttons */
}

.menu-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f0f0f0; /* Light grey background */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Soft shadow for depth */
}

.menu-bar button {
  margin: 5px;
  padding: 5px 10px;
  background-color: #A3D5FF; /* Soft Blue */
  color: #333; /* Darker text color for better contrast */
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-bar button:hover {
  background-color: #91cbee; /* Slightly darker for hover */
}

.menu-bar button.is-active {
  background-color: #7b9ec9; /* A more saturated version for active state */
}

.menu-bar button:disabled {
  background-color: #E0E0E0; /* Light grey for disabled state */
  cursor: not-allowed;
}

.toggle-menu-btn {
  margin-bottom: 10px; /* Adds space below the button */
  cursor: pointer;
  padding: 5px 10px;
  background-color: #007bff; /* Example: a blue background */
  color: white;
  border: none;
  border-radius: 5px;
}

.toggle-menu-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}